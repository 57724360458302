'use strict'
/* ================================
* Utils
* ================================ */
import { gsap } from "gsap";


/**
 * Convert a kebab-case string into a camelCase one
 * @param  {string} string The string to convert
 * @return {string}        The camelCased string
 */
export const camelCase = function(string) {
	return string.toLowerCase().replace(/-(.)/g, (match, group) => {
		return group.toUpperCase()
	})
}



/**
 * Convert a kebab-case string into a PascalCase one
 * @param  {string} string The string to convert
 * @return {string}        The transformed string
 */
export const pascalCase = function(string) {
	// Remove dashes and transform
	// following letter to uppercase
	string = string.toLowerCase()
		.replace(/-(.)/g, (match, group) => {
			return group.toUpperCase()
		})

	// Return string with first letter
	// transformed to uppercase
	return string.charAt(0).toUpperCase() + string.slice(1)
}



/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param  {number}    wait       Timer
 * @param  {boolean}   immediate  Launch the function immediately
 * @param  {function}  func       The function that needs debounce
 * @return {function}             A function to bind to the event debounced
 */
export const debounce = function(wait, immediate, func) {
	let timeout;

	return function() {
		const context = this;
		const args = arguments;
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		let callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}



/**
 * Animate scroll for anchor links
 * @return {void}
 */
export const smoothScroll = function(event) {
	if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
		const $target = document.querySelector(this.hash)
		if ($target) {
			let pos = $target.getBoundingClientRect();
			event.preventDefault();

			gsap.to(
				document.querySelectorAll('html, body'),
				{
					duration: 0.8,
					scrollTop: window.scrollY + pos.top,
					ease: "expo.inOut"
				}
			)
			return false
		}
	}
}




/**
 * Convert degrees to rad
 * @param  {integer} degrees The angle to convert
 * @return {integer}         The converted angle in rad
 */
export const degToRad = (degrees) => {
	return degrees * Math.PI / 180
}



/**
 * Get a random integer in the given interval
 * @param  {integer} min The interval's minimum
 * @param  {integer} max The interval's maximum
 * @return {integer}     A random integer in the given interval
 */
export const getRandomInt = (min, max) => {
	return Math.floor(Math.random() * (max - min)) + min
}