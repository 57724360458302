import { smoothScroll } from './utils/utils'
import Blazy from 'blazy'
import axios from 'axios'
import fslightbox from './vendors/fslightbox'
import Splitting from 'splitting'
import Flickity from 'flickity'
import gsap from 'gsap'
import inView from 'in-view'

const HTML = document.querySelector('html');

class App {

  constructor() {

    // Prevent scrollTop on click on a[href="#"] links
    let linksEmpty = document.querySelectorAll('a[href="#"]');
    if( linksEmpty ) linksEmpty.forEach((el) => { el.addEventListener('click', function(e) { e.preventDefault(); }) });

    // Smooth scroll for anchors link
    let linksAnchor = document.querySelectorAll('a[href*="#"]:not([href="#"])');
    if( linksAnchor ) linksAnchor.forEach((el) => { el.addEventListener('click', smoothScroll) });

    // Check if mac for better font aliasing
    if (navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC') HTML.classList.add('is-mac')

    let results = Splitting({
      target : 'h2, h1', 
      by : 'lines'
    });

    let whitespace = document.createElement('span');
    whitespace.classList.add('whitespace')
    whitespace.innerHTML = ' ';

    results.forEach((r) => {
      r.el.innerHTML = '';
      r.lines.forEach((line) => {

        let span = document.createElement('span');
        span.classList.add('line')
        r.el.appendChild(span)

        line.forEach((word, i) => {
          span.appendChild(word);

          if( i < line.length - 1)
            span.appendChild(whitespace.cloneNode(true));
        })
      })
      r.el.classList.add('ready')
    })

    // Lazyload
    let blazy = new Blazy({
      breakpoints: [{
        width: 420,
        src: 'data-src-small' 
       }],
      selector : '.js-lazy',
      success : function(elt) {
        if( elt.closest('figure') )
          elt.closest('figure').classList.add('img-is-loaded')
      }
    })

    // Sidebar trigger
    let sidebarTrigger = document.querySelectorAll('.trigger-sidebar');
    if( sidebarTrigger ) {
      sidebarTrigger.forEach(link => {
        link.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();

          HTML.classList.toggle('show-sidebar')

          if( HTML.classList.contains('show-sidebar')) {
            HTML.addEventListener('click', toggleSidebar)
          } else {
            HTML.removeEventListener('click', toggleSidebar)  
          }
        })
      })

      function toggleSidebar(e) {
        if( !e.target.classList.contains('sidebar') && 
            !e.target.closest('.sidebar') ) {
          e.stopPropagation();
          e.preventDefault();

          sidebarTrigger[0].dispatchEvent(new Event('click'))
        }
      }
    }

    // Form
    let inputs = document.querySelectorAll('input');
    if( inputs ) {
      inputs.forEach(input => {
        input.addEventListener('focus', e => {
          input.parentNode.classList.add('fill')
        })

        input.addEventListener('blur', e => {
          let val = input.value;

          if( val == '' ) input.parentNode.classList.remove('fill')
        })
      })
    }

    // Form
    let form = document.querySelector('.form');
    if( form ) {
      form.addEventListener('submit', e => {
        e.preventDefault();
        form.classList.add('loading');

        let msgContainer = form.querySelector('.msg');
        msgContainer.innerHTML = '';

        let f = new FormData(form)

        axios({
          method : 'post',
          url : './includes/.ajax.php',
          data : f
        })
        .then(response => {
          form.classList.remove('loading');

          if( response.data && response.data.statut !== null ) {
            if( response.data.statut ) {
              let fill = form.querySelectorAll('.fill');
              if( fill ) {
                fill.forEach(el => {
                  el.classList.remove('fill')
                })
              }
              form.reset();
            }

            let msg = document.createElement('div');
            msg.innerHTML = response.data.msg
            msgContainer.append(msg)
          }
        })

        return false;
      })
    }

    // Freescroll
    let freescroll = document.querySelectorAll('.gallery, .pyramide');
    if( freescroll ) {
      freescroll.forEach(el => {
        if( window.innerWidth <= 992 ) {
          let bg = el.querySelector('.pyramide__bg');

          let flkty = new Flickity( el, {
            cellSelector: '.pyramide__item, .gallery__item:not(.u-dn)',
            freeScroll: true,
            contain: true,
            // disable previous & next buttons and dots
            prevNextButtons: false,
            pageDots: false,
            on: {
              scroll : function( progress ) {
                let distance = this.slideableWidth - this.size.width;
                if( bg ) {
                  gsap.set(bg, { x : `-${progress * distance}px` });
                }
              }
            }
          });

          if( bg ) {
            window.addEventListener('resize', e => {
              bg.style.width = `${flkty.slideableWidth}px`;
            })
            window.dispatchEvent(new Event('resize'));
          }
        }
      })
    }
    
    inView.offset(window.innerHeight * .1);
    inView('.js-reveal:not(.is-visible)')
    .on('enter', function(elt) { 
        elt.classList.add('is-visible');

        elt.dispatchEvent(new Event('inviewport'))
    })

    return this
  }

  /**
   * Init the page
   * @return {object} The current instance
   */
  init() {
    console.log(`${this.constructor.name} is init`)

    return this;
  }
}


window.addEventListener('load', function() {
  new App('home');
});